<template>
  <div
    :class="['b-card-products', hitTheGoal && 'b-card-products__disabled']"
    @click="openFileDialog()"
  >
    <v-btn fab class="b-card-products__btn" :disabled="hitTheGoal === true">
      <v-icon class="b-card-products__btn--icon">
        {{ icon }}
      </v-icon>
    </v-btn>

    <h3 class="b-card-products__title">
      {{ title }}
    </h3>

    <span class="b-card-products__subtitle">
      {{ description }}
    </span>

    <template v-if="amountRegistered !== null && amountContract !== null">
      <v-divider class="b-card-products__divider" />
      <span class="b-card-products__percentage">
        <strong>
          {{ amountRegistered }}
        </strong>
        /
        {{ amountContract }}
      </span>
    </template>
    <input
      ref="input"
      type="file"
      class="d-none"
      accept=".xlsx,.xls,.csv"
      @change="handleFile($event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    amountRegistered: {
      type: Number,
      require: true
    },
    amountContract: {
      type: Number,
      require: true
    },
    productType: {
      type: String,
      require: true
    },
    hasTemplate: {
      type: Boolean,
      require: true
    },
    implementationId: {
      type: String,
      require: true
    },
    cannotEdit: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    title: '',
    icon: ''
  }),

  computed: {
    hitTheGoal() {
      if (!this.cannotEdit) {
        return true;
      }

      if (this.productType === 'Template unificado') {
        return false;
      } else {
        if (this.amountRegistered === null && this.amountContract === null) {
          return true;
        } else if (this.amountRegistered > 0 || !this.hasTemplate) {
          return true;
        } else {
          return false;
        }
      }
    },

    description() {
      if (
        this.productType !== 'Template unificado' &&
        this.amountRegistered === null &&
        this.amountContract === null
      ) {
        return 'A implantação não possui este produto.';
      } else if (
        !this.hasTemplate &&
        this.productType !== 'Template unificado'
      ) {
        return 'Realize o upload no template Biobe.';
      } else {
        return 'Clique para realizar o upload do arquivo.';
      }
    }
  },

  mounted() {
    switch (this.productType) {
      case 'Assistência médica':
        this.icon = 'fa-solid fa-stethoscope';
        this.title = 'Saúde';
        break;
      case 'Assistência odontológica':
        this.icon = 'fa-solid fa-tooth';
        this.title = 'Odonto';
        break;
      case 'Seguro de vida':
        this.icon = 'fa-solid fa-heart-pulse';
        this.title = 'Vida';
        break;

      default:
        this.icon = 'fa-regular fa-comments';
        this.title = 'Biobe';
        break;
    }
  },

  methods: {
    openFileDialog() {
      if (!this.hitTheGoal) {
        this.$refs.input.value = null;
        this.$refs.input.click();
      }
    },

    handleFile({ target }) {
      const params = {
        implementationId: this.implementationId,
        productType: this.productType,
        file: target.files[0]
      };

      this.$emit('file', params);
    }
  }
};
</script>
